<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          date: new Date('2021-08-27T09:10:40.922Z'),
          value: 100.0,
        },
        {
          id: 2,
          date: new Date('2021-08-27T09:10:40.922Z'),
          value: 100.0,
        },
        {
          id: 3,
          date: new Date('2021-08-27T09:10:40.922Z'),
          value: 100.0,
        },
      ],

      headers: [
        { text: 'Data', value: 'date' },
        { text: 'Valor', value: 'value' },
        { text: 'Ações', value: 'actions' },
      ],
    };
  },
};
</script>

<template>
  <div class="mt-10">
    <h5 class="pb-1">Informações do plano</h5>
    <v-card>
      <v-card-text class="pa-7">
        <v-row>
          <v-col md="6">
            <h2 class="mb-5 white--text">Facelabs Home</h2>
            <h4>Plano mensal</h4>
            <h4>Renova automáticamente em 10 de agosto de 2021</h4>
          </v-col>
          <v-col md="6" class="d-flex justify-end align-center">
            <div>
              <v-btn rounded text class="mr-10">Cancelar plano</v-btn>
              <v-btn rounded color="primary" class="mr-5" to="/change-plan">Alterar plano</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <h5 class="pb-1 mt-10">Informações de pagamento</h5>
    <v-card>
      <v-card-text class="pa-7">
        <v-row>
          <v-col md="6">
            <h2 class="mb-5 white--text">R$ 100,00 / mês</h2>
            <h4>Próximo pagamento programado para 10 de agosto de 2021</h4>
            <h4>Visa com final ****1010</h4>
          </v-col>
          <v-col md="6" class="d-flex justify-end align-center">
            <div>
              <v-btn rounded outlined color="white" class="mr-5">Gerenciar pagamento</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <h5 class="pb-1 mt-10">Histórico de pagamento</h5>
    <v-card>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :items="items"
          :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
          class="elevation-1 no-line-row"
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | dateFormat('DD/MM/YY') }}
          </template>
          <template v-slot:[`item.value`]="{ item }"> R$ {{ item.value }} </template>
          <template v-slot:[`item.actions`]>
            <v-icon small class="mr-2"> mdi-eye </v-icon>
            <v-icon small> mdi-printer </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
